import { Autocomplete, BsTable, BsTableNoContent, Button, DateInput, Loader, Menu, TextField, Checkbox } from '@brightsource/brightsource-ui-lib';
import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { ResponsiveDevice } from '../../../constants/styles';
import { AddIcon, MoreIcon, SearchNormal, TrashIcon1 } from '../../components/svg';
import remindersStore from '../../../stores/reminders.store';
import qs from 'qs';
import DefaultImageComponent from '../../components/DefaultImage.component';
import utilsService from '../../../services/utils.service';
import userStore from '../../../stores/user.store';
import AddEditReminderModalComponent from '../../components/modalComponents/AddReminderModal.component';
import AssignToModalComponent from '../../components/modalComponents/AssignToModal.component';
import ConfirmationModal from '../../components/modalComponents/ConfirmationModal.component';
import notificationsStore from '../../../stores/notifications.store';
import { Link } from 'react-router-dom';


function DashboardReminders() {
  const [reminders, setReminders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedReminder, setSelectedRemider] = useState(null);
  const [isDeleteOne, setIsDeleteOne] = useState(false)
  const [isDeleteMany, setIsDeleteMany] = useState(false)

  const [filters, setFilters] = useState({dueDate: {from:null, to:null}, assignee: null, search: ''});
  const recruitersList = userStore.recruitersList

  const [modal, setModal] = useState({
    createReminder: false,
    assignToModal: false,
    reminderModal: false,
    deleteReminder: false
  });

  const dueDateOptions = 
  [
    {
      elId: 'today',
      value: 'Due Today'
    },
    {
      elId: 'overdue',
      value: 'OverDue'
    },
    {
      elId: 'upcoming',
      value: 'Upcoming'
    }
  ]


  const columns = useMemo(() => {
    return [
      { id: 'selected', label: '' },
      { id: 'object', label: 'Object' },
      { id: 'duedate', label: 'Due Date' },
      { id: 'reminderdate', label: 'Reminder Date' },
      { id: 'task', label: 'Task' },
      { id: 'assignedTo', label: 'Assigned To' },
      { id: 'actions', label: 'Action' },
    ];
  }, []);

  useEffect(() => {
    (async function loader() {
      await getListReminder();
    })()
  }, [filters]);

  const getListReminder = async () => {
    setLoading(true);
    if (recruitersList.length == 0) {
      userStore.getRecruiters()
    }
    const payload = {
      dueDateFrom: filters?.dueDate?.from?.getTime() || null,
      dueDateTo: filters?.dueDate?.to?.getTime() || null,
      assignee: filters?.assignee?.slug,
      keyword: filters?.search
    }
    const listReminders = await remindersStore.getlistReminders(qs.stringify(payload))
    setReminders(listReminders)
    setLoading(false)
  }

  const handleClick = (selectedModal) => {
    setModal((modal) => ({ ...modal, [selectedModal]: true }));
  };

  const handleDelete = async() => {
    const ids = isDeleteOne ? selectedReminder?._id || '' : selectedIds.filter(i => !!i).join(',')
    const resp = await remindersStore.deleteReminder(ids)
    if (resp && resp.data && resp.data.isError) {
        notificationsStore.showToast(`Delete task failed. ${resp.data.errorMessage}`, 'error');
    }
    else {
        notificationsStore.showToast(`Delete task successfully`, 'success');
        getListReminder();
    }
    setModal((modal) => ({ ...modal, deleteReminder: false }))
    setIsDeleteOne(false)
    setIsDeleteMany(false)
  }

  const rows = useMemo(() => {
    return reminders.map((reminder, index) => {
      const assignee = recruitersList.find(i => i.slug == reminder.assigneeSlug);
      const isSelected = selectedIds.includes(reminder._id);
      const onCheck = () => {
        if (isSelected) {
          setSelectedIds((ids) => ids.filter((id) => id !== reminder._id));
        } else {
          setSelectedIds((ids) => [...ids, reminder._id]);
        }
      };
      const selected = <Checkbox checked={isSelected} onChange={onCheck} iconType="square" />;
      return {
        id: index,
        selected,
        object: (
          <div className='object-card'>
            <div className='object-image'>
              {reminder.entityType == 'profile' ?
              <DefaultImageComponent
                url={utilsService.getProfileImage({...reminder.entityData, profileImageId: reminder?.entityData?.imageURL})}
                alt={reminder.entityData.candidateName}
                profileName={reminder.entityData.candidateName}
              />
              : reminder.entityType == 'job' ?
              <DefaultImageComponent
                url={utilsService.getCompanyImage({...reminder.entityData, profileImageUrl: reminder?.entityData?.imageURL})}
                alt={'Company'}
                company={true}
              />:
              <DefaultImageComponent
                url={utilsService.getCompanyImage({...reminder.entityData, profileImageUrl: reminder?.entityData?.imageURL})}
                alt={'Company'}
                company={true}
              />}
            </div>
            <div className='object-infor'>
            <span className='name'>
              {reminder.entityType == 'profile' ? 
              (<Link to={`/profile/${reminder.entitySlug}`}>{reminder.entityData.candidateName}</Link>) : 
              reminder.entityType == 'job' ?
              (<Link to={`/jobs/${reminder.entitySlug}`}>{reminder.entityData.jobName}</Link>):
              (<Link to={`/company/${reminder.entitySlug}`}>{reminder.entityData.companyName}</Link>)
              }
            </span>

              <span className='position'>{reminder.entityType == 'profile' ? reminder.entityData.position : reminder.entityData.companyName}</span>
              <span className='location'>{`${reminder.entityData.city} - ${reminder.entityData.country}`}</span>
            </div>
          </div>
        ),
        duedate: utilsService.getFormattedDate(reminder.dueDate, 5),
        reminderDate: utilsService.getFormattedDate(reminder.reminderDate, 5),
        task: reminder.detail,
        assignedTo: assignee?.firstName + " " + assignee?.lastName,
        actions: (
          <Actions className="actions">
            <div className='actions' onClick={() => { handleClick('deleteReminder'); setSelectedRemider(reminder); setIsDeleteOne(true) }} >
              <TrashIcon1 />
            </div>
            <Menu showAsIcon items={[
              { text: 'Assign To', onClick: () => { handleClick('assignToModal'); setSelectedRemider(reminder) } }, 
              { text: 'Edit Reminder', onClick: () => {handleClick('reminderModal'), setSelectedRemider(reminder)} }, 
              { text: 'Create Reminder', onClick: () => { handleClick('reminderModal', setSelectedRemider({...reminder, detail:'', dueDate: null, reminderDate: null })) } }
            ]}>
              <MoreIcon />
            </Menu>
          </Actions>
        ),
      };
    });
  }, [reminders, selectedIds]);

  return (
    <Wrapper>

      <div className='filter-bar'>
        <DateInput
          size="small"
          value={filters?.dueDate?.from}
          onChange={(date) => setFilters({...filters, dueDate: {...filters.dueDate, from: date}})}
          label="Due Date"
          placeholder="From"
          onReset={() => setFilters({...filters, dueDate: {...filters.dueDate, from: null}})}
        />

        <DateInput
          size="small"
          value={filters?.dueDate?.to}
          onChange={(date) => setFilters({...filters, dueDate: {...filters.dueDate, to: date}})}
          label="Due Date"
          placeholder="To"
          onReset={() => setFilters({...filters, dueDate: {...filters.dueDate, to: null}})}
          minDate={filters?.dueDate?.from || new Date('1970')}
        />

        <Autocomplete
            value={filters?.assignee}
            onChange={(e, item) => {setFilters({...filters, assignee: item})}}
            options={recruitersList.map(i => ({...i, elId: i._id, value: utilsService.getDisplayName(i)}))}
            getOptionLabel={(option) => `${option.value || ''}`}
            label='Assigned To'
        />

        <TextField
          size="small"
          value={filters.search}
          onChange={(event) => setFilters({...filters, search: event.target.value})}
          allowClear
          onClear={() => setFilters({...filters, search: '' })}
          rightIcon={<SearchNormal w={16} h={16} />}
          placeholder="Search"
        />

        <div className='group-btn'>
          {selectedIds.length > 0 && (
            <div className="del-btn">
              <Button
                onClick ={ () => { handleClick('deleteReminder'); setIsDeleteMany(true) }}
              >
                Delete All
              </Button> 
            </div>
          )}
          <div className="new-btn">
            <Button
              minimal
              rightIcon={<AddIcon fill="#fff" />}
              onClick={() => setModal((modal) => ({ ...modal, createReminder: true }))}
            >
              New Task
            </Button>
          </div>
        </div>

        {modal.createReminder && (
          <AddEditReminderModalComponent
            buttonText="YES"
            cancelButtonText="NO"
            closeEvent={() => setModal((modal) => ({ ...modal, createReminder: false }))}
            refresh={() => getListReminder()}
          />
        )}

        {modal.assignToModal && (
          <AssignToModalComponent
            buttonText="YES"
            cancelButtonText="NO"
            closeEvent={() => setModal((modal) => ({ ...modal, assignToModal: false }))}
            refresh={() => getListReminder()}
            data={selectedReminder}
          />
        )}

        {modal.deleteReminder && isDeleteOne && (
          <ConfirmationModal
            title={`You are about to delete reminder has subject: ${selectedReminder?.detail}`}
            subtitle="Are you sure?"
            buttonText="Delete"
            closeEvent={() => {setModal((modal) => ({ ...modal, deleteReminder: false })); setIsDeleteOne(false)}}
            confirmEvent={handleDelete}
            // isLoading={deleteLoader}
          />
        )}

        {modal.deleteReminder && isDeleteMany && (
          <ConfirmationModal
            title={`You are about to delete ${selectedIds.length} reminder`}
            subtitle="Are you sure?"
            buttonText="Delete"
            closeEvent={() => {setModal((modal) => ({ ...modal, deleteReminder: false })); setIsDeleteMany(false)}}
            confirmEvent={handleDelete}
            // isLoading={deleteLoader}
          />
        )}

        {modal.reminderModal && (
          <AddEditReminderModalComponent
            buttonText="YES"
            cancelButtonText="NO"
            closeEvent={() => setModal((modal) => ({ ...modal, reminderModal: false }))}
            refresh={() => getListReminder()}
            data={selectedReminder}
          />
        )}
      </div>

      <Content>
        <BsTable 
          columns={columns} 
          rows={rows} 
          disabledSortColumns={['actions']} 
          selectable={false}
        />
        {loading && <Loader />}
        {!loading && rows.length === 0 && <BsTableNoContent />}
      </Content>
    </Wrapper>
  );
}

export default DashboardReminders;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .default-pic-frame,
  .default-image-component > img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    font-size: 16px;

    ${ResponsiveDevice.TABLET} {
      width: 56px;
      height: 56px;
    }
  }

  .default-image-component > svg {
    width: 60px;
    height: 60px;
    ${ResponsiveDevice.TABLET} {
      width: 56px;
      height: 56px;
    }
  }

  th.actions-cell {
    text-align: right;
    padding-right: 24px;
  }

  .object-card {
    display: flex;
    justify-content: start;
    align-items: center;
    .object-image {
      margin-right: 15px
    }
    .object-infor {
      display: flex;
      flex-direction: column;
      .name {
        text-transform: capitalize;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
  .filter-bar {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    gap: 40px;
    background-color: #fff;
    border-radius: 12px 12px 0px 0px;

    .autocomplete,
    .text-field,
    .date-input {
      width: 250px;
      margin-bottom: 0px;
    }

    .text-field {
      margin-top: 35px;
    }

    .group-btn {
      display: flex;
      margin-left: auto;
    }

    .new-btn {
      margin-left: auto;
      button {
        margin-top: 35px;
        padding: 10px 24px;
        border-radius: 60px;
        background-color: #307ff6;
        transition: all 100ms ease-in-out;
  
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 100%;
  
        &:hover {
          color: #fff;
          background-color: #002a84;
        }
      }
    }

    .del-btn {
      margin-right: 10px;
      button {
        margin-top: 35px;
        padding: 10px 24px;
        border-radius: 60px;
        border: 1px solid;
        background-color: #fff;
        transition: all 100ms ease-in-out;
  
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 100%;
      }
    }

  }
`;

const Content = styled.div`
  flex: 1;
  background-color: #fff;
  border-radius: 0px 0px 12px 12px;

  .MuiTableContainer-root {
    .MuiTableHead-root {
      .MuiTableCell-root:nth-child(5) { // Task column
        text-align: center;
      }

      .MuiTableCell-root:nth-child(2) { // Object column
        width: 25%;
      }

      .MuiTableCell-root:nth-child(3), // Due Date
      .MuiTableCell-root:nth-child(4) { // Reminder Date
        width: 12%;
      }

      .MuiTableCell-root:nth-child(6) { // Assigned To
        width: 12%;
      }
    }
  }

  .bs-menu {
    text-align: right;
  }

  a.name {
    color: #6c6c6c;
    transition: color 150ms ease-in-out;

    &:hover {
      color: #307ff6;
    }
  }

  ${ResponsiveDevice.TABLET} {
    .MuiTableHead-root {
      display: none;
    }

    .MuiTableCell-root {
      /* padding: 8px; */
      vertical-align: top;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
