import React, {Component} from 'react';
import {Suggest} from '@blueprintjs/select';
import './multiSelect.scss';
import onClickOutside from 'react-onclickoutside';
import { MinusIcon } from '../svg';
import utilsService from '../../../services/utils.service';
import DefaultImageComponent from '../DefaultImage.component';

class SuggestImageComponent extends Component {

    constructor(props) {
        super(props);
        this.lastSearchText = ''
    }


    handleNormalClick = (item, handleClick) => {
        handleClick();
        this.props.selectItem(item)
    }

    handleExcludeClick = (item, handleClick) => {
        handleClick();
        this.props.selectExcludedItem(item);
    }

    renderItem = (item, { handleClick, modifiers, index }) => {
        const {classes, moreInfoRenderer, hasExcluded} = this.props;
        let classesCus = 'multi-item suggest-item ';
        if (classes) {
            classesCus += ` ${classes}-multi`;
        }

        let content = item.value;

        if (moreInfoRenderer) {
            classesCus += ' multi-content';
            content = [];
            content.push(<span key={item.value + '32dd0'} className='content'>{item.value}</span>);
            moreInfoRenderer.forEach((ite, idx) => {
                content.push(<span key={ite + idx} className={'sub-content sub-content_' + idx}>{item[ite]}</span>);
            });
        }

        return (

            <div className={`${classesCus} suggest-image`} key={index} > 
                <div className='content-image'>
                    { content !== '---None---' && content !== '---Unknown---' && (
                        this.props.entityType == 'profile' ?
                        <DefaultImageComponent
                            width={40}
                            url={utilsService.getProfileImage(item)}
                            alt={item.value}
                            profileName={item.value}
                        />:
                        this.props.entityType == 'job' ?
                        <DefaultImageComponent
                            width={40}
                            url={utilsService.getCompanyImage(item.companyData)}
                            alt={'Company'}
                            company={true}
                        />: <DefaultImageComponent
                            width={40}
                            url={utilsService.getCompanyImage(item)}
                            alt={'Company'}
                            company={true}
                        />
                    )}
                </div>
                <label onClick={() => this.handleNormalClick(item, handleClick)}>{content}</label>       
                {hasExcluded && <div className="exclude" onClick={() => this.handleExcludeClick(item, handleClick)}>
                     <MinusIcon/>
                </div>}
            </div>
        );
    };

    renderValue = item => {
        switch(item.value) {
            case '---None---':
            case '---Unknown---':
            return ' ';
                
            default:
                return item.value;
        }
        
    };

    onQueryChange = text => {
        const { query } = this.props
        if (query && this.lastSearchText !== text) {

            // to resolve a bug while query calls twice
            this.lastSearchText = text;
            query(text);
        }
    };

    getRelevantClasses(classes, validation, valid, hasArrowIcon, isLoading, selectedItem) {
        let res = '';

        if (classes) {
            res += ` ${classes} `;
        }

        if (validation) {
            res += ` ${valid ? 'valid' : 'invalid'} `;
        }

        if(isLoading) {
            res += 'loading';
        }
        else {
            if(hasArrowIcon) {
                res += 'arrow-icon';
            }
        }

        if(selectedItem && selectedItem.isExcluded) {
            res += 'excluded';
        }

        return res;
    }

    // SEARCH ITEMS
    itemPredicate = (item, cat) => {
        const { numberOfCharsToPredicate, showListCompanyLocations } = this.props;
            //-1 = default value 
            if (showListCompanyLocations) {
                return true
            }
            return (item.length >= numberOfCharsToPredicate && cat.value &&
                (cat.value.toLowerCase().indexOf(item.toLowerCase()) >= 0 || cat.elId === -1));
    };

    returnable(props, nextProps, val) {
        return props[val] !== nextProps[val];
    }

    shouldComponentUpdate = nextProps => {
        const {
            items, selectedItem
        } = this.props;

        if (this.returnable(this.props, nextProps, 'defaultValue')) {
            return true;
        }
        if (this.returnable(this.props, nextProps, 'classes')) {
            return true;
        }
        if (this.returnable(this.props, nextProps, 'valid')) {
            return true;
        }
        if (this.returnable(this.props, nextProps, 'validation')) {
            return true;
        }
        if (this.returnable(this.props, nextProps, 'hasArrowIcon')) {
            return true;
        }
        if (this.returnable(this.props, nextProps, 'label')) {
            return true;
        }
        if (this.returnable(this.props, nextProps, 'isLoading')) {
            return true;
        }

        if (this.returnable(this.props, nextProps, 'placeholder')) {
            return true;
        }

        if (Array.isArray(items) && Array.isArray(nextProps.items) && nextProps.items.length !== items.length) {
            return true;
        }

        if (nextProps.selectedItem !== selectedItem) {
            if (selectedItem && nextProps.selectedItem && typeof selectedItem === 'object' && typeof nextProps.selectedItem === 'object') {
                const selectedItemKeys = Object.keys(selectedItem);
                const nextPropsSelectedItemKeys = Object.keys(nextProps.selectedItem);
                let verdict = false;
                if (selectedItemKeys.length !== nextPropsSelectedItemKeys.length) {
                    verdict = true;
                }

                if (selectedItemKeys.length >= 2 && nextPropsSelectedItemKeys.length >= 2) {
                    if (selectedItem[selectedItemKeys[0]] !== nextProps.selectedItem[nextPropsSelectedItemKeys[0]]) {
                        verdict = true;
                    }

                    if (selectedItem[selectedItemKeys[1]] !== nextProps.selectedItem[nextPropsSelectedItemKeys[1]]) {
                        verdict = true;
                    }
                }

                return verdict;
            } else {
                return true;
            }
        }
        
        return false;
    };

    handleClickOutside = evt => {
        this.props.handleClickOutside && this.props.handleClickOutside();
    };

    render() {
        const { defaultValue, classes, items, selectedItem, valid, validation, hasArrowIcon,
            disabled, label, isLoading, placeholder } = this.props;

        if (defaultValue && items && items.length > 0 && items[0].elId !== -1) {
            items.unshift({ elId: -1, value: defaultValue });
        }
        const multiClasses = this.getRelevantClasses(classes, validation, valid, hasArrowIcon, isLoading, selectedItem);
        
        return (
            <div className='suggestion-wrapper'>
                {label && (<label>{label}</label>)}
                <Suggest
                    inputProps={{placeholder: placeholder}}
                    className={multiClasses}
                    items={items}
                    onItemSelect={this.selectHandle}
                    itemRenderer={this.renderItem}
                    inputValueRenderer={this.renderValue}
                    itemPredicate={this.itemPredicate}
                    selectedItem={selectedItem}
                    disabled={disabled}
                    onQueryChange={this.onQueryChange}
                />
            </div>
        );
    }
}

SuggestImageComponent.propTypes = {
    // bla: PropTypes.string,
};

SuggestImageComponent.defaultProps = {
    numberOfCharsToPredicate: 0,
    defaultValue: '---None---'
};

export default onClickOutside(SuggestImageComponent);
